import styled from "@emotion/styled";
import { H2 } from "@styles/Global.styles";
import mq from "@styles/mq";

export const HeaderTitle = styled(H2)`
    padding-top: 20px;
    ${mq({
        paddingTop: ['20px', '40px', '40px'],
        marginBottom: ['32px', '24px', '24px']
    })}
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
`

export const SliderContainer = styled.div`
${mq({
    backgroundColor: ['black', '#f8f8f8', '#f8f8f8'],
    color: ['white', 'black', 'black'],
    paddingBottom:['0px','60px', '60px']
})};
cursor: pointer;
`
