import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from "@components/SEO"
import {
  SignupBannerWrapper,
  mapToKiwiVIPVisible,
} from "@components/SignupBanner"
import {
  ImageButtonBanner,
  mapToImageLinkBanner,
} from "@components/ImageButtonBanner"
import { HomepageBlack } from "@components/HomepageBlack"
import {
  ImageInformationList,
  mapToInfoBannerContainer,
} from "@components/ImageInformationList"
import { QuickCombinationFinder } from "@components/CombinationFinder"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import {
  CustomerReviewCardSlider,
  mapToCustomerReviewData,
} from "@components/CustomerReviewCardSlider"
import { mapToCtaImageCards } from "@components/CallToActionCardList/mapper"
import CallToActionImageCardList from "@components/CallToActionCardList/CallToActionImageCardList"
import PlateDesignImageGrid from "@components/PlateDesignImageGrid/PlateDesignImageGrid"
import { mapToPlateDesignImageGrid } from "@mappers/PlateDesignImageGrid/PlateDesignImageGrid.mapper"
import { mapToPlateStorySliderItems } from "@mappers/PlateStorySlider/PlateStorySlider.mapper"
import PlateStorySlider from "@components/PlateStorySlider/PlateStorySlider"
import FeatureProductImageGrid from "@components/FeatureProductImageGrid/FeatureProductImageGrid"
import { mapDataToModel } from "@components/HomepageBlack/mapper"
import { PopularPlatesCarousel } from "@components/PopularPlatesCarousel"
import { mapToPlateDesignList } from "@mappers/ProductModels/mapper"
import { mapToHomepageItem } from "@mappers/Homepage/Homapage.mapper"
import PlateDesignSlider from "@components/HomepagePopularPlatesCarousel/PlateDesignSlider"
import { mapToHowItWorksItems } from "@mappers/HowItWorks/howItWorks.mapper"
import CompHowItWorks from "@components/HowItWorks/CompHowItWorks"

interface Props {
  data: any
  addCombination: Function
  combination: string
}
interface States { }

class Homepage extends React.Component<Props, States> {
  render() {
    const { elements } = this.props.data.kontentItemHomepageV2
    const data = this.props.data.kontentItemAbLandingPage.elements.homepage.linked_items[0]
    const components = data.elements.components
    if (components === null || components === undefined) {
      return null
    }
    const {
      cta_cards_with_images,
      homepage_carousels,
      info_banner,
      image_link_banner,
      featured_plate_designs,
      featured_plate_stories,
      how_it_works
    } = elements

    const seoData = mapToSEO(elements)
    const ctaCardData = mapToCtaImageCards(cta_cards_with_images.linked_items)
    const plateDesignImageGridData = mapToPlateDesignImageGrid(
      featured_plate_designs.linked_items[0].elements
    )
    const popularPlateDesignTitle = featured_plate_designs.linked_items[0].elements.heading.value
    const plateStorySliderData = mapToPlateStorySliderItems(
      featured_plate_stories.linked_items
    )
    const compHowItWorksData = mapToHowItWorksItems(how_it_works.linked_items[0].elements)

    const infoBannerData = mapToInfoBannerContainer(
      info_banner.linked_items[0].elements
    )

    const imageLinkBannerData = image_link_banner && image_link_banner.linked_items.length > 0 ? mapToImageLinkBanner(
      image_link_banner.linked_items[0].elements
    ) : null;

    
    const kiwiVip = mapToKiwiVIPVisible(elements)
    const customerReviewData = mapToCustomerReviewData(
      elements.customer_reviews.linked_items[0].elements, true
    )
    return (
      <Layout version="dark">
        <SEO {...seoData} />
        {
          components.linked_items.map((item: any) => {
            switch (item.__typename) {
              case 'KontentItemCompBlackTheme':
                const homepageBlackData = mapToHomepageItem(
                  item.elements
                )
                return (
                  <HomepageBlack
                    data={homepageBlackData}
                  />
                )
              case 'KontentItemCompCreateNewPlate':
                var title = item.elements.title?.value
                var subtitle = item.elements.subtitle?.value
                var vehicleType = item.elements.vehicletype?.value.find(() => true)?.name
                var vehicleTheme = item.elements.vehicletheme?.value.find(() => true)?.name
                const apiUrl = this.props.data.site.siteMetadata.apiUrl;
                const reCaptchaSiteKey = this.props.data.site.siteMetadata.reCaptchaSiteKey;
                const reCaptchaSiteKeyV2 = this.props.data.site.siteMetadata.reCaptchaSiteKeyV2;

                return (
                  <GenericBannerContainer
                    padding={{
                      mobile: {
                        top: 28,
                        bottom: 5,
                      },
                      desktop: {
                        top: 20,
                        bottom: 45,
                      },
                    }}
                    backgroundColor="#000"
                  >
                    <QuickCombinationFinder
                      title={title}
                      subtitle={subtitle}
                      vehicleType={vehicleType}
                      vehicleTheme = {vehicleTheme}
                      apiUrl= {apiUrl}
                      reCaptchaSiteKey={reCaptchaSiteKey}
                      reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
                    />
                  </GenericBannerContainer>
                )
              case 'KontentItemCompFeatureProducts':
                return (
                  <div
                  >
                    <FeatureProductImageGrid
                      apiUrl={this.props.data.site.siteMetadata.apiUrl}
                      elements={mapDataToModel(item.elements)}
                    ></FeatureProductImageGrid> 
                  </div>
                )
              case 'KontentItemCompRestylePlate':
                return (
                  <GenericBannerContainer
                    padding={{
                      mobile: {
                        top: 10,
                        bottom: 45,
                      },
                      desktop: {
                        top: 20,
                        bottom: 83,
                      },
                    }}
                    backgroundColor={theme.brand.colors.beige}
                  >
                    <CallToActionImageCardList
                      backgroundColor={theme.brand.colors.beige}
                      alternateButtonLayout={false}
                      cards={ctaCardData}
                    />
                  </GenericBannerContainer>
                )
              case 'KontentItemCompPopularPlateDesigns':
                return (
                  <GenericBannerContainer
                    padding={{
                      mobile: {
                        top: 57,
                        bottom: 70,
                      },
                      desktop: {
                        top: 80,
                        bottom: 93,
                      },
                    }}
                    backgroundColor={theme.brand.colors.white}
                  >
                    <PlateDesignImageGrid
                      {...plateDesignImageGridData}
                    ></PlateDesignImageGrid>
                  </GenericBannerContainer>
                )
              case 'KontentItemCompCarousel':
                const { plate_designs } = item.elements.content.linked_items.filter((i: any) => i.__typename === 'KontentItemPlateDesignLandingPage').find(() => true).elements;
                const plateDesignList = mapToPlateDesignList(plate_designs.linked_items, 'ABC123', undefined);
                
                return (
                  <PlateDesignSlider
                    plateDesigns={plateDesignList}
                    title={popularPlateDesignTitle}
                />
                )
              case 'KontentItemCompPlateStorySlider':
                return (
                  <GenericBannerContainer
                    padding={{
                      mobile: {
                        top: 60,
                        bottom: 75,
                      },
                      desktop: {
                        top: 100,
                        bottom: 95,
                      },
                    }}
                    backgroundColor={theme.brand.colors.beige}
                  >
                    <PlateStorySlider {...plateStorySliderData}></PlateStorySlider>
                  </GenericBannerContainer>
                )
              case 'KontentItemCompHowItWorks': 
                return (
                  <GenericBannerContainer
                    padding={{
                      mobile: {
                        top: 48,
                        bottom: 40,
                      },
                      desktop: {
                        top: 100,
                        bottom: 95,
                      },
                    }}
                    backgroundColor={theme.brand.colors.black}
                  >
                    <CompHowItWorks {...compHowItWorksData}></CompHowItWorks>
                  </GenericBannerContainer>
                )
              case 'KontentItemCompFooter':
                return (
                  <React.Fragment>
                    {/* <GenericBannerContainer
                      padding={{
                        mobile: {
                          top: 60,
                          bottom: 50,
                        },
                        desktop: {
                          top: 95,
                          bottom: 85,
                        },
                      }}
                      backgroundColor={theme.brand.colors.white}
                    >
                      <ImageInformationList
                        listItems={infoBannerData.listItems}
                        backgroundColor={infoBannerData.backgroundColor}
                        columns={4}
                        horizontalLayout={false}
                        titleOverlayImage={false}
                        titleColor="black"
                        textColor="black"
                        showAll={true}
                      />
                    </GenericBannerContainer> */}
                    {imageLinkBannerData &&<ImageButtonBanner {...imageLinkBannerData} />}
                    <CustomerReviewCardSlider {...customerReviewData} />
                    {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
                  </React.Fragment>
                )
              default:
                break;
            }
          })
        }
      </Layout>
    )
  }
}

export default Homepage
export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  
  kontentItemAbLandingPage {
      elements {
        homepage {
          linked_items {
            ... on
            KontentItemKiwiplatesHomepage {
              elements {
                seo__nofollow {
                  name
                  value {
                    codename
                    name
                  }
                }
                seo__noindex {
                  name
                  value {
                    codename
                    name
                  }
                }
                seo__page_description {
                  name
                  value
                }
                seo__page_title {
                  name
                  value
                }
                components {
                  linked_items {
                    ... on KontentItemCompBlackTheme {
                      id
                      elements {
                        tablet_image {
                          type
                          value {
                            url
                            width
                            height
                          }
                        }
                        mobile_image {
                          type
                          value {
                            url
                            width
                            height
                          }
                        }
                        desktop_image {
                          value {
                            url
                            height
                            width
                          }
                        }
                        description {
                          value
                        }
                        btn___redesign_plate_title {
                          value
                        }
                        btn___redesign_plate_link {
                          value
                        }
                        btn___redesign_plate_description {
                          value
                        }
                        btn___new_plate_title {
                          value
                        }
                        btn___new_plate_link {
                          value
                        }
                        btn___new_plate_description {
                          value
                        }
                        new {
                          value
                        }
                        mobile___text_align {
                          value
                        }
                        redesign_plate_gtm_unique_id {
                          value
                        }
                        create_plate_gtm_unique_id {
                          value
                        }
                        gifting_text {
                          value
                        }
                        gifting_link {
                          value
                        }
                        gifting_link_gtm_id {
                          value
                        }
                      }
                    }
                    ... on KontentItemCompFeatureProducts {
                      id
                      elements {
                        product {
                          linked_items {
                            id
                            ... on KontentItemContentTileItem {
                              id
                              elements {
                                description {
                                  value
                                }
                                new_from_label {
                                  value
                                }
                                image {
                                  value {
                                    url
                                  }
                                }
                                new_from_price {
                                  value
                                }
                                order {
                                  value
                                }
                                restyle_from_label {
                                  value
                                }
                                resytle_from_price {
                                  value
                                }
                                view_more_label {
                                  value
                                }
                                view_more_url {
                                  value
                                }
                                mobile_image {
                                  value {
                                    url
                                  }
                                }
                                gtm_unique_id {
                                  value
                                }
                                subtitle{
                                  value
                                }
                                plate_image_url{
                                  value
                                }
                                create_button_text{
                                  value
                                }
                                create_button_url{
                                  value
                                }
                              }
                            }
                            ... on KontentItemCompCarousel {
                              id
                              elements {
                                content {
                                  linked_items {
                                    ... on KontentItemProductCategory {
                                      id
                                      elements {
                                        feature_image {
                                          value {
                                            url
                                          }
                                          name
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on KontentItemCompCarousel {
                      id
                      elements {
                        content {
                          linked_items {
                            ... on KontentItemPlateDesignLandingPage {
                              id
                              elements {
                                plate_designs {
                                  linked_items {
                                    ... on KontentItemProductCategory {
                                      elements {
                                        category_name {
                                          value
                                        }
                                        url {
                                          value
                                        }
                                        category_description_rich {
                                          value
                                        }
                                        new_design {
                                          value {
                                            codename
                                            name
                                          }
                                        }
                                        feature_image {
                                          value {
                                            url
                                          }
                                          name
                                        }
                                        vehicle_types {
                                          value {
                                            name
                                            codename
                                          }
                                        }
                                        fixed_fomat_price {
                                          value
                                        }
                                        flexi_format_price {
                                          value
                                        }
                                        upgrade_starting_price {
                                          value
                                        }
                                        redesign_starting_price {
                                          value
                                        }
                                        premium_3_character_price {
                                          value
                                        }
                                        premium_2_character_price {
                                          value
                                        }
                                        product_designs {
                                          linked_items {
                                            ... on KontentItemPlateDesign {
                                              elements {
                                                name {
                                                  value
                                                }
                                                dealer_only_design {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                disable_dual_sizing {
                                                  value {
                                                    codename
                                                    name
                                                  }
                                                }
                                                authorized_dealers {
                                                  linked_items {
                                                    ... on KontentItemAuthorizedDealers {
                                                      elements {
                                                        title {
                                                          value
                                                        }
                                                        link_message {
                                                          value
                                                        }
                                                        description {
                                                          value
                                                        }
                                                        dealer_contacts {
                                                          linked_items {
                                                            ... on KontentItemDealerContact {
                                                              elements {
                                                                dealer_name {
                                                                  value
                                                                }
                                                                contact_name {
                                                                  value
                                                                }
                                                                phone {
                                                                  value
                                                                }
                                                                formatted_phone_number {
                                                                  value
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                                transaction_types {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                vehicle_types {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                kpi_plate_design_code {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                kpi_plate_foreground_color {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                plate_size {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                plate_color {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                base_price {
                                                  value
                                                }
                                                redesign_price {
                                                  value
                                                }
                                                govt_upgrade_price {
                                                  value
                                                }
                                                captions {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                max_message_length__mm_ {
                                                  value
                                                }
                                                bottom_caption_max_length__mm_ {
                                                  value
                                                }
                                                message_colors {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                european_brand_name {
                                                  value {
                                                    name
                                                    codename
                                                  }
                                                }
                                                dual_size_plate {
                                                  linked_items {
                                                    ... on KontentItemPlateDesign {
                                                      elements {
                                                        name {
                                                          value
                                                        }
                                                        dealer_only_design {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        disable_dual_sizing {
                                                          value {
                                                            codename
                                                            name
                                                          }
                                                        }
                                                        authorized_dealers {
                                                          linked_items {
                                                            ... on KontentItemAuthorizedDealers {
                                                              elements {
                                                                title {
                                                                  value
                                                                }
                                                                link_message {
                                                                  value
                                                                }
                                                                description {
                                                                  value
                                                                }
                                                                dealer_contacts {
                                                                  linked_items {
                                                                    ... on KontentItemDealerContact {
                                                                      elements {
                                                                        dealer_name {
                                                                          value
                                                                        }
                                                                        contact_name {
                                                                          value
                                                                        }
                                                                        phone {
                                                                          value
                                                                        }
                                                                        formatted_phone_number {
                                                                          value
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                        transaction_types {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        vehicle_types {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        kpi_plate_design_code {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        kpi_plate_foreground_color {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        plate_size {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        plate_color {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        base_price {
                                                          value
                                                        }
                                                        redesign_price {
                                                          value
                                                        }
                                                        govt_upgrade_price {
                                                          value
                                                        }
                                                        captions {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        max_message_length__mm_ {
                                                          value
                                                        }
                                                        bottom_caption_max_length__mm_ {
                                                          value
                                                        }
                                                        message_colors {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                        european_brand_name {
                                                          value {
                                                            name
                                                            codename
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          itemCodenames
                        }
                      }
                    }
                    ... on KontentItemCompCreateNewPlate {
                      id
                      elements {
                        subtitle {
                          name
                          value
                        }
                        title {
                          name
                          value
                        }
                        vehicletype {
                          name
                          value {
                            name
                          }
                        }
                        vehicletheme {
                          name
                          value {
                            name
                          }
                        }
                      }
                    }
                    ... on KontentItemCompHowItWorks {
                      id
                      elements {
                        title {
                          value
                        }
                        subtitle {
                          value
                        }
                        description {
                          value
                        }
                        learn_more_button_text {
                          value
                        }
                        learn_more_button_url {
                          value
                        }
                        steps {
                          linked_items {
                            ... on KontentItemRichHtmlBlock {
                              elements {
                                title {
                                  value
                                }
                                content {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }  
                  }
                }
              }
            }
          }
        }
      }
  }
  
  kontentItemHomepageV2 {
    elements {
      seo__page_title {
        value
      }
      seo__page_description {
        value
      }
      seo__noindex {
        value {
          name
        }
      }
      seo__nofollow {
        value {
          name
        }
      }
      url {
        value
      }
      general_page_content__title {
        value
      }
      general_page_content__kiwivip_signup {
        value {
          name
          codename
        }
      }
      general_page_content__kiwivip_signup_version {
        value {
          name
          codename
        }
      }
      cta_cards_with_images {
        linked_items {
          ... on KontentItemCallToActionImageCard {
            id
            elements {
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              background_image {
                value {
                  description
                  url
                }
              }
              subtitle {
                value
              }
              text_colors {
                value {
                  codename
                  name
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      info_banner {
        linked_items {
          ... on KontentItemInfoBannerContainer {
            elements {
              background_colors {
                value {
                  codename
                }
              }
              text_colors {
                value {
                  codename
                }
              }
              text_alignment {
                value {
                  codename
                }
              }
              items {
                linked_items {
                  ... on KontentItemInfoBannerItem {
                    elements {
                      icon {
                        value {
                          url
                          name
                          description
                        }
                      }
                      title {
                        value
                      }
                      description {
                        value
                      }
                      linktext {
                        value
                      }
                      linkurl {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      homepage_carousels {
        linked_items {
          ... on KontentItemHomepageCarousel {
            elements {
              desktop {
                value {
                  name
                  url
                }
              }
              tablet {
                value {
                  name
                  url
                }
              }
              mobile {
                value {
                  name
                  url
                }
              }
              link_url {
                value
              }
            }
          }
        }
      }
      image_link_banner {
        linked_items {
          ... on KontentItemImageLinkBanner {
            elements {
              image {
                value {
                  url
                  name
                  description
                }
              }
              title {
                value
              }
              button__text {
                value
              }
              button__button_type {
                value {
                  codename
                }
              }
              button__button_color_theme {
                value {
                  codename
                }
              }
              button__url_slug {
                value
              }
              image_link {
                value
              }
              image_size {
                value {
                  name
                }
              }
              show_button {
                value {
                  name
                }
              }
              show_title {
                value {
                  name
                }
              }
            }
          }
        }
      }
      homepage_sliders {
        linked_items {
          ... on KontentItemHomepageSlider {
            elements {
              link_url {
                value
              }
              image {
                value {
                  url
                  name
                  description
                }
              }
              tablet_image {
                value {
                  url
                  name
                  description
                }
              }
              mobile_image {
                value {
                  url
                  name
                  description
                }
              }
            }
          }
        }
      }
      customer_reviews {
        linked_items {
          ... on KontentItemCustomerReviewCardBanner {
            elements {
              title {
                value
              }
              subtitle{
                value
              }
              title_2{
                value
              }              
              rating {
                value
              }
              totalreviews {
                value
              }
              background_colors {
                value {
                  codename
                }
              }
              cards {
                linked_items {
                  ... on KontentItemCustomerReviewCard {
                    elements {
                      customer_name {
                        value
                      }
                      avatar {
                        value {
                          url
                        }
                      }
                      rating {
                        value
                      }
                      title {
                        value
                      }
                      description {
                        value
                      }
                      create_date{
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      featured_plate_designs {
        linked_items {
          ... on KontentItemPlateDesignsImageGridContainer {
            id
            elements {
              heading {
                value
              }
              plate_designs {
                linked_items {
                  ... on KontentItemPlateDesignImageGridItem {
                    id
                    elements {
                      background_image {
                        value {
                          description
                          url
                        }
                      }
                      button__button_color_theme {
                        value {
                          name
                          codename
                        }
                      }
                      button__button_type {
                        value {
                          codename
                          name
                        }
                      }
                      button__text {
                        value
                      }
                      button__url_slug {
                        value
                      }
                      hide_on_mobile {
                        value {
                          codename
                          name
                        }
                      }
                      price {
                        value
                      }
                      title {
                        value
                      }
                    }
                  }
                }
              }
              sub_heading {
                value
              }
            }
          }
        }
      }
      featured_plate_stories {
        linked_items {
          ... on KontentItemPlateStorySliderItem {
            id
            elements {
              background_image {
                value {
                  description
                  url
                }
              }
              background_image___mobile {
                value {
                  url
                  description
                }
              }
              button__button_color_theme {
                value {
                  name
                  codename
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              title {
                value
              }
            }
          }
        }
      }
      how_it_works {
        linked_items {
          ... on KontentItemCompHowItWorks {
            id
            elements {
              title {
                value
              }
              subtitle {
                value
              }
              description {
                value
              }
              learn_more_button_text {
                value
              }
              learn_more_button_url {
                value
              }
              steps {
                linked_items {
                  ... on KontentItemRichHtmlBlock {
                    elements {
                      title {
                        value
                      }
                      content {
                        value
                      }
                    }
                  }
                }
              }
              step_icons {
                value {
                  url
                }
              }
            }
          }  
        }
      }
    }
  }
}
`
